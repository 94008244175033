import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { pluginFrontendApiRef, PluginFrontendApi } from './api';

export const pluginFrontPlugin = createPlugin({
  id: 'plugin-front',
  apis: [
    createApiFactory({
      api: pluginFrontendApiRef,
      deps: { fetchApi: fetchApiRef, configApi: configApiRef },
      factory: ({ fetchApi, configApi }) => {
        const backendBaseUrl = configApi.getOptionalString('backend.baseUrl');
        if (!backendBaseUrl) {
          throw new Error('Backend base URL is not configured');
        }

        return new PluginFrontendApi(backendBaseUrl, fetchApi);
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const PluginFrontPage = pluginFrontPlugin.provide(
  createRoutableExtension({
    name: 'PluginFrontPage',
    component: () =>
      import('./components/MainComponent').then(m => m.MainComponent),
    mountPoint: rootRouteRef,
  }),
);
