import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import CodeIcon from '@material-ui/icons/Code';
import BuildIcon from '@material-ui/icons/Build';
import CloudIcon from '@material-ui/icons/Cloud';
import PollIcon from '@material-ui/icons/Poll';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import Dashboard from '@material-ui/icons/Dashboard';
import ArchiveIcon from '@material-ui/icons/Archive';
import LoopIcon from '@material-ui/icons/Loop';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import Timelapse from '@material-ui/icons/Timelapse';
import LinkIcon from '@material-ui/icons/Link';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import FeedbackIcon from '@material-ui/icons/Feedback';
import { GitHub } from '@material-ui/icons';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PolicyIcon from "@material-ui/icons/Policy"
import { SupportAgent } from '@mui/icons-material';

import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { adminPanelAccessPermission, awsAccountsBillingPermission, awsAccountsCloudopsPermission, awsAccountsSecurityPermission, restartpodsPermission } from '@internal/backstage-plugin-aws-accounts-common';

const useSidebarLogoStyles = makeStyles(theme => ({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
  },
  sidebarItem: {
    width: '100%',
    '& span': {
      fontWeight: theme.palette.type === 'dark' ? '500' : 'bold',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  typography: {
    cursor: 'pointer',
    padding: '5px 5px',
  },
  icon: {
    margin: '0 10px 0 8px',
  },
}));

const UpdateIsOpen = ({ setState }: { setState: Function }) => {
  const { isOpen } = useSidebarOpenState();
  setState(isOpen);

  return <></>;
};

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const classes = useSidebarLogoStyles();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {}, [isOpen]);
  return (
  <SidebarPage>
    <Sidebar>
      <UpdateIsOpen setState={setIsOpen} />
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="catalog" text="Home" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CodeIcon} to="create" text="Templates" />
        <SidebarItem icon={AccessibilityNewIcon} to="khatu-gpt" text="Khatu GPT" />
        <SidebarItem icon={LocalLibrary} to="opsgenie" text="Opsgenie" />
        <SidebarItem
          icon={SupportAgent}
          to="opsgenie/create-alert"
          text="On Call"
        />

        <SidebarItem
          icon={PolicyIcon}
          to="software-catalog"
          text="GR Management"
        />
        <SidebarItem icon={GitHub} to="khatu-groups" text="Groups" />
        <SidebarItem icon={Dashboard} to="reports" text="Reports" />
        <SidebarItem icon={PollIcon} to="billing-boards" text="Billing Boards" />

        <SidebarItem
          icon={FingerprintIcon}
          to="cloud-carbon-footprint"
          text="Cloud Carbon Footprint"
        />
        <SidebarItem
          icon={ArchiveIcon}
          to="rds-query-runner"
          text="Consultas SQL"
        />
        <SidebarItem
          icon={BuildIcon}
          to="entity-validation"
          text="Validator"
        />

        <SidebarItem
          icon={CloudUploadIcon}
          to="aws-accounts-request"
          text="Cloud Accounts"
        />

        {/* <SidebarItem
          icon={FeedbackIcon}
          to="feedback"
          text="Feedback"
        /> */}

        <RequirePermission
          permission={restartpodsPermission}
          errorPage={<></>}
        >
          <SidebarItem
            icon={LoopIcon}
            to="pod-annotations"
            text="Restart Pods"
          />
        </RequirePermission>

        <RequirePermission
            permission={awsAccountsBillingPermission}
            errorPage={<></>}
          >
            <SidebarItem
              icon={CloudIcon}
              to="/aws-accounts-billing-panel"
              text="Aws Accounts Billing Panel"
            />
        </RequirePermission>
        <RequirePermission
            permission={awsAccountsCloudopsPermission}
            errorPage={<></>}
          >
            <SidebarItem
              icon={CloudIcon}
              to="/aws-accounts-cloudops-panel"
              text="Aws Accounts Cloudops Panel"
            />
          </RequirePermission>
          <RequirePermission
            permission={awsAccountsSecurityPermission}
            errorPage={<></>}
          >
            <SidebarItem
              icon={CloudIcon}
              to="/aws-accounts-security-panel"
              text="Aws Accounts Security Panel"
            />
          </RequirePermission>
          {/* <SidebarItem icon={BrightnessAutoIcon} to="awana-tools" text="Awana" /> */}
        {/* End global nav */}
        <SidebarDivider />
        {/* Items in this group will be scrollable if they run out of space */}
        <RequirePermission
          permission={adminPanelAccessPermission}
          errorPage={<></>}
        >
          <SidebarScrollWrapper style={{'flexShrink': 0}}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <LinkIcon className={classes.icon} />
                  <Typography>
                    Admin &hellip;
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <SidebarItem
                    icon={SupervisorAccountIcon}
                    to="admin-panel"
                    text="Admin Panel"
                  />
                  <SidebarItem
                    icon={SupervisedUserCircleIcon}
                    to="group-panel"
                    text="Group Panel"
                  />
                  <SidebarItem
                    icon={CloudCircleIcon}
                    to="accounts-admin-panel"
                    text="Accounts Panel"
                  />
                  <SidebarItem
                    icon={Timelapse}
                    to="time-saver"
                    text="timeSaver"
                  />
                  <SidebarItem icon={GitHub} to="copilot/enterprise" text="Copilot" />
                </AccordionDetails>
              </Accordion>
          </SidebarScrollWrapper>
        </RequirePermission>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
)};
