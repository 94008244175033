import { createApiRef, FetchApi } from '@backstage/core-plugin-api';

class PluginFrontendApiError extends Error {
  /**
   * A custom error class for errors that occur in the frontend API.
   *
   * @param statusText The error message.
   * @param status The HTTP status code of the error.
   * @param isPluginFrontendApiError A flag indicating whether this is an
   *     instance of PluginFrontendApiError. This is set to true by default
   *     when the constructor is called directly, and false when the error
   *     is created from another error.
   */
  constructor(
    statusText: string,
    private readonly _status: number,
    private readonly _isPluginFrontendApiError: boolean = false,
  ) {
    // Use the Error constructor to set the error message and stack trace.
    super(statusText);
    // Set the name of the error to the class name.
    this.name = 'PluginFrontendApiError';
  }

  /**
   * Returns the HTTP status code of the error.
   *
   * @returns {number} The HTTP status code of the error.
   */

  /**
   * The HTTP status code of the error.
   */

  /**
   * The HTTP status code of the error.
   *
   * @returns {number} The HTTP status code of the error.
   */
  get status(): number {
    return this._status;
  }

  public get isPluginFrontendApiError(): boolean {
    return this._isPluginFrontendApiError;
  }

  /**
   * Converts an unknown error into a PluginFrontendApiError.
   *
   * @param error The error to convert.
   * @returns {PluginFrontendApiError} The converted error.
   */
  public static fromError(error: unknown): PluginFrontendApiError {
    // Check if the error is already a PluginFrontendApiError
    if (error instanceof PluginFrontendApiError) {
      return new PluginFrontendApiError(error.message, error.status, true);
    }

    // Check if the error is a generic Error
    if (error instanceof Error) {
      return new PluginFrontendApiError(error.message, 500, false);
    }

    // Return a generic PluginFrontendApiError for unknown error types
    return new PluginFrontendApiError('Unknown error', 500, false);
  }
}

export class PluginFrontendApi {
  constructor(private readonly baseUrl: string, private readonly fetchApi: FetchApi) { }

   /**
   * A helper function that makes a request to the given endpoint and
   * returns the response as JSON.
   *
   * @param endpoint The URL to request. Example: `/test`
   * @param init The request options.
   * @returns The response, or an error if the request fails.
   */
   public async getActiveRegion<T extends unknown>(
    init?: RequestInit,
  ): Promise<T | never> {
    try {
      const { fetch } = this.fetchApi;
      const url = `${this.baseUrl}/api/proxy/region-qr`;
      const response = await fetch(url, init);

      if (!response.ok) {
        throw new PluginFrontendApiError(response.statusText, response.status);
      }
      const json = await response.json();

      return json as T;
    } catch (error) {
      throw PluginFrontendApiError.fromError(error);
    }
  }

  public async postActivateRegion<T extends unknown>(
    endpoint: string,
  ): Promise<T | never> {
    try {
      const { fetch } = this.fetchApi;
      const url = `${this.baseUrl}/api/proxy/activate-region/${endpoint}`;
      const response = await fetch(url, {method:"POST"});

      const json = await response.json();

      if (!response.ok) {
        throw new PluginFrontendApiError(response.statusText, response.status);
      }

      return json as T;
    } catch (error) {
      throw PluginFrontendApiError.fromError(error);
    }
  }



  public async postSetPercentageRegion<T extends unknown>(
    endpoint: string,
    body?: Record<string, unknown>, // Parámetro opcional para el cuerpo de la solicitud
  ): Promise<T | never> {
    try {
      const { fetch } = this.fetchApi;
      const url = `${this.baseUrl}/api/proxy/percentage-region/${endpoint}`;
      
      // Configurar las opciones de la solicitud
      const options: RequestInit = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Asegura que el servidor interprete el cuerpo como JSON
        },
        body: body ? JSON.stringify(body) : undefined, // Serializar el cuerpo si existe
      };
  
      const response = await fetch(url, options);
  
      const json = await response.json();
  
      if (!response.ok) {
        throw new PluginFrontendApiError(response.statusText, response.status);
      }
  
      return json as T;
    } catch (error) {
      throw PluginFrontendApiError.fromError(error);
    }
  }

  public async getPercentageRegion<T extends unknown>(
    endpoint: string,
  ): Promise<T | never> {
    try {
      const { fetch } = this.fetchApi;
      const url = `${this.baseUrl}/api/proxy/percentage-region-get/${endpoint}`;
      
      // Realizamos la solicitud GET
      const response = await fetch(url, { method: "GET" });
  
      // Verificamos si la respuesta fue exitosa
      if (!response.ok) {
        // Lanza un error si la respuesta no es exitosa
        throw new PluginFrontendApiError(response.statusText, response.status);
      }
  
      // Intentamos convertir la respuesta a JSON
      let json: T;
      try {
        json = await response.json();
      } catch (jsonError) {
        // Si la respuesta no es un JSON válido, lanzamos un error adecuado
        throw new Error("Respuesta de la API no es un JSON válido.");
      }
  
      // Retornamos la respuesta correctamente tipada
      return json;
  
    } catch (error) {
      // Lanza el error capturado y lo maneja adecuadamente
      throw PluginFrontendApiError.fromError(error);
    }
  }
  
  






  
}



export const pluginFrontendApiRef = createApiRef<PluginFrontendApi>({
  id: 'plugin.frontend.api',
});
