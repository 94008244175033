import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import {
  microsoftAuthApiRef,
  SignInPageProps,
} from '@backstage/core-plugin-api';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
// import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { ValidateGitlabGroupFieldExtension } from './scaffolder/GitlabGroupPicker';
import { FlushcachePage } from '@internal/backstage-plugin-flushcache';
import { AdminPanelPage } from '@internal/backstage-plugin-admin-panel';
import { AccountsAdminPanelPage } from '@internal/backstage-plugin-accounts-admin-panel';
import {
  adminPanelAccessPermission,
  awsAccountsBillingPermission,
  awsAccountsCloudopsPermission,
  awsAccountsSecurityPermission,
  restartpodsPermission,
} from '@internal/backstage-plugin-aws-accounts-common';
import {
  AccountsApprovalPanelPage,
  ReviewersTeams,
} from '@internal/backstage-plugin-aws-accounts-panel';
import { AwsAccountsRequestPage } from '@internal/backstage-plugin-aws-accounts-request';
import { EntityValidationPage } from '@backstage-community/plugin-entity-validation';
import { BillingBoardsPage } from '@internal/backstage-plugin-billing-boards';
import { GroupPanelPage } from '@internal/backstage-plugin-group-panel';
import { KhatuGptPage } from '@internal/backstage-plugin-khatu-gpt';
import { OpsgeniePage } from '@internal/backstage-plugin-opsgenie';
import { PodAnnotationsPage } from '@internal/backstage-plugin-pod-annotations';
import { ReportsPage } from '@internal/backstage-plugin-reports';

import { RdsQueryRunnerPage } from '@internal/backstage-plugin-rds-query-runner';
import { TimeSaverPage } from '@internal/backstage-plugin-timesaver-internal';
import {
  CustomDarkThemeProvider,
  CustomLightThemeProvider,
} from './theme/CustomTheme';
import { KhatuGroupsPage } from '@internal/backstage-plugin-khatu-groups';
import { CloudCarbonFootprintPage } from '@internal/backstage-plugin-khatu-ccf';
import { CopilotIndexPage } from '@backstage-community/plugin-copilot';
import { AwanaToolsPage } from '@internal/backstage-plugin-awana-tools';
import { AwanaStoryTellerPage } from '@internal/backstage-plugin-awana-story-teller';
import { AwanaTestgeniusPage } from '@internal/backstage-plugin-awana-testgenius';
import { AwanaCraftestPage } from '@internal/backstage-plugin-awana-craftest';
import { PluginMultiRegionPage } from '@internal/backstage-plugin-multi-region-frontend';
import { SoftwareCatalogPage } from '@nx-idp/backstage-plugin-software-catalog-frontend';
import { templateFilter } from './scaffolder/templateFilter';

import {
  feedbackPlugin,
  GlobalFeedbackPage,
  // OpcFeedbackComponent
} from '@backstage-community/plugin-feedback';

const KhatuSignInPage: React.FC<SignInPageProps> = ({ ...props }) => {
  return (
    <div>
      <SignInPage
        {...props}
        providers={[
          {
            id: 'microsoft-auth-provider',
            title: 'Microsoft Active Directory',
            message: 'Sign in to IDP App using your AAD account',
            apiRef: microsoftAuthApiRef,
          },
        ]}
        title="Bienvenido a Khatu X"
        align="center"
        onSignInSuccess={async identityApi => {
          const { token } = await identityApi.getCredentials();
          if (token) {
            localStorage.setItem('KhatuToken', token);
          }
          return props.onSignInSuccess(identityApi);
        }}
      />
    </div>
  );
};

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(feedbackPlugin.externalRoutes, {
      viewDocs: feedbackPlugin.routes.root
    })
  },
  components: {
    SignInPage: props => <KhatuSignInPage {...props} />,
  },
  themes: [
    {
      id: 'my-dark-theme',
      title: 'Khatu Dark Theme',
      variant: 'dark',
      Provider: CustomDarkThemeProvider,
    },
    {
      id: 'my-light-theme',
      title: 'Khatu Light Theme',
      variant: 'light',
      Provider: CustomLightThemeProvider,
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Recommended',
              filter: entity =>
                entity?.metadata?.tags?.includes('recommended') ?? false,
            },
          ]}
          templateFilter={templateFilter}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <ValidateGitlabGroupFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={adminPanelAccessPermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/flushcache" element={<FlushcachePage />} />
    <Route path="/opsgenie" element={<OpsgeniePage />} />
    <Route path="/reports" element={<ReportsPage />} />
    <Route path="/entity-validation" element={<EntityValidationPage />} />
    <Route
      path="/admin-panel"
      element={
        <RequirePermission permission={adminPanelAccessPermission}>
          <AdminPanelPage />
        </RequirePermission>
      }
    />
    <Route
      path="/group-panel"
      element={
        <RequirePermission permission={adminPanelAccessPermission}>
          <GroupPanelPage />
        </RequirePermission>
      }
    />
    <Route path="/khatu-groups" element={<KhatuGroupsPage />} />
    <Route
      path="/aws-accounts-billing-panel"
      element={
        <RequirePermission permission={awsAccountsBillingPermission}>
          <AccountsApprovalPanelPage {...{ team: ReviewersTeams.BILLING }} />
        </RequirePermission>
      }
    />
    <Route
      path="/aws-accounts-cloudops-panel"
      element={
        <RequirePermission permission={awsAccountsCloudopsPermission}>
          <AccountsApprovalPanelPage {...{ team: ReviewersTeams.CLOUDOPS }} />
        </RequirePermission>
      }
    />
    <Route
      path="/aws-accounts-security-panel"
      element={
        <RequirePermission permission={awsAccountsSecurityPermission}>
          <AccountsApprovalPanelPage {...{ team: ReviewersTeams.SECURITY }} />
        </RequirePermission>
      }
    />
    <Route
      path="/accounts-admin-panel"
      element={
        <RequirePermission permission={adminPanelAccessPermission}>
          <AccountsAdminPanelPage />
        </RequirePermission>
      }
    />
    <Route path="/aws-accounts-request" element={<AwsAccountsRequestPage />} />
    <Route path="/billing-boards" element={<BillingBoardsPage />} />
    <Route path="/software-catalog" element={<SoftwareCatalogPage />} />
    <Route path="/rds-query-runner" element={<RdsQueryRunnerPage />} />
    <Route
      path="/time-saver"
      element={
        <RequirePermission permission={adminPanelAccessPermission}>
          <TimeSaverPage />
        </RequirePermission>
      }
    />
    <Route
      path="/pod-annotations"
      element={
        <RequirePermission permission={restartpodsPermission}>
          <PodAnnotationsPage />
        </RequirePermission>
      }
    />
    <Route path="/khatu-gpt" element={<KhatuGptPage />} />
    <Route
      path="/cloud-carbon-footprint"
      element={<CloudCarbonFootprintPage />}
    />
    <Route path="/copilot" element={<CopilotIndexPage />} />
    <Route path="/awana-tools" element={<AwanaToolsPage />} />
    <Route path="/awana-story-teller" element={<AwanaStoryTellerPage />} />
    <Route path="/awana-testgenius" element={<AwanaTestgeniusPage />} />
    <Route path="/awana-craftest" element={<AwanaCraftestPage />} />
    <Route
      path="/multi-region"
      element={
        <RequirePermission permission={awsAccountsCloudopsPermission}>
          <PluginMultiRegionPage />
        </RequirePermission>
      }
    />
    <Route path="/feedback" element={<GlobalFeedbackPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
      {/* <OpcFeedbackComponent /> */}
    </AppRouter>
  </>,
);
