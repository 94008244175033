interface Template {
  apiVersion: string;
  kind: string;
  metadata: Metadata;
  spec: Spec;
}

interface Metadata {
  name: string;
  title: string;
  description: string;
  tags: string[];
  substitute: Substitute;
}

interface Substitute {
  engineering: Engineering;
}

interface Engineering {
  cloudops: number;
  security: number;
  development: number;
  quality: number;
}

interface Spec {
  owner: string;
  type: string;
  lifecycle: string;
  parameters: Parameter[];
  steps: Parameter[];
  output: Output;
}

interface Output {
  links: Parameter[];
}

interface Parameter {
  $yaml: string;
}
/**
 * Filter templates by lifecycle.
 *
 * This function will return `true` if the template is in the experimental or production
 * lifecycle and `false` otherwise.
 *
 * @param entity - The entity to filter
 * @returns `true` if the template is in the experimental or production lifecycle, `false` otherwise
 */
export function templateFilter<T extends unknown>(entity: T): boolean {
  const template = entity as Partial<Template>;

  // The lifecycle is an optional field, so we need to check it is present
  // before trying to access it.
  const lifecycle = template.spec?.lifecycle ?? 'deprecated';

  // We want to return true for experimental templates, and false otherwise.
  const validLifecycles = ['experimental', 'production'];
  return validLifecycles.includes(lifecycle);
}
